<!--  -->
<template>
<div class="erg_huhw">
    <img class="eg_huwf" src="../../image/zenmekandaiwodekehu@3x(1)@2x.png" alt="">
</div>
</template>

<script>
export default {
//import引入的组件需要注入到对象中才能使用
components: {},
data() {
return {

}
},
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {},
//方法集合
methods: {

},
//生命周期 - 挂载完成（访问DOM元素）
mounted() {

}
}
</script>
<style scoped>
/* @import url(); 引入css类 */
*{
    margin: 0;
    padding: 0;
}
.eg_huwf{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
</style>